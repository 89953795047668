import { Component, Inject, OnInit } from '@angular/core';
import { UnishieldFetchStore } from 'src/app/shared/data-access/unishield/unishield.store';
import { DateRangeMockStore } from 'src/app/shared/store-mocks/date-range.store';
import { FiltersStore } from '../stores/filters.store';
import { SecurityFetchStore } from '../stores/security-fetch.store';
import { PageIndexStore } from './page-index.store';
import { UniAuthFacade } from 'unifonic-spa-common/src';

@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss'],
  providers: [
    UnishieldFetchStore,
    PageIndexStore,
    FiltersStore,
    SecurityFetchStore
  ],
})
export class PageIndexComponent implements OnInit {

  constructor(
    private store: PageIndexStore,
    private filters: FiltersStore,
    private authFacade: UniAuthFacade,
    private securityFetchStore: SecurityFetchStore,
    @Inject('environment') public environment,
  ) { }



  ngOnInit(): void {
    const accountId = this.authFacade.userMe?.user?.account?.id || '';
    this.filters.patchState({accountId})
  }

  vm$ = this.store.vm$


  selectTab(tab: string){
    if(tab === 'applications'){
      window.location.href = this.environment.ucHostUrl + '/cloud/unishield'
    }

  }
}
