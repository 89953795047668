import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpQueryModule, SettingFactory, SettingsHttpService, SettingsService, TokenInterceptorFactory, UniAuthRepository, UniAuthService, UniCommonModule, UniSharedModule } from '@unifonic/common';

import { AppRoutingModule } from './app-routing.module';
import { LazyTranslateLoader } from './app-translate-loader';
import { AppComponent } from './app.component';
import { LogAnalyserModule } from './log-analyser/log-analyser.module';
import { InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ReportsModule } from './reports/reports.module';
import { environment } from 'src/environments/environment';
import { DashboardCampaignsModule } from './dashboard-campaigns/dashboard-campaigns.module';
import { SecurityUnishieldModule } from './security-unishield/security-unishield.module';
import { CustomUrlInterceptor } from './shared/utils/custom-url.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    UniCommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      }
    }),
    UniSharedModule,
    BrowserAnimationsModule,
    HttpQueryModule,
    LayoutModule,
    LogAnalyserModule,
    ReportsModule,
    DashboardCampaignsModule,
    SecurityUnishieldModule
  ],
  providers: [
    CookieService,
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: TokenInterceptorFactory(),
      multi: true,
      deps: [UniAuthService, UniAuthRepository, SettingsService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: () => new CustomUrlInterceptor(),
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SettingFactory,
      deps: [SettingsHttpService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, settings: SettingsService) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            //@ts-ignore //TODO: settings type is in different project, should be generic as well.
            uri: settings?.settings?.ciGraphQlUrl,
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache'
            }
          }

        };
      },
      deps: [HttpLink, SettingsService],
    },



],
  bootstrap: [AppComponent]
})
export class AppModule {}
