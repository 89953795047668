import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import { Channels } from "./channels.store";

export enum CustomerStatus {
  QUEUED = "Queued",
  SENT = "Sent",
  DELIVERED = "Delivered",
  UNDELIVERED = "Undelivered",
  DELIVERY_NOT_CONFIRMED = "Delivery not confirmed",
  REJECTED = "Rejected",
  NONE = "None"
}

export enum WhatsappStatus {
  SENT = "Sent",
  DELIVERED = "Delivered",
  READ = "Read",
  REJECTED = "Rejected",
  FAILED = "Failed",
  DELIVERY_NOT_CONFIRMED = "Delivery not confirmed",
}

export enum VoiceStatus {
  QUEUED = "QUEUED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  BUSY = "BUSY",
  FAILED = "FAILED",
  NO_ANSWER = "NO ANSWER",
  UNKNOWN = "UNKNOWN",
  REJECTED = "REJECTED",
}

export enum FlowStatus {
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

type x = keyof typeof Channels
type State = {
    loading: boolean
    loaded: boolean
    entities: {
        [P in x]?: {
          label: string,
          value: string
        }[]
    }
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: {
      [Channels.SMS]: [
        {
          label: 'ci.common.brand.queued',
          value: CustomerStatus.QUEUED,
        },{
          label: 'ci.common.brand.sent',
          value: CustomerStatus.SENT
        },{
          label: 'ci.common.brand.delivered',
          value: CustomerStatus.DELIVERED
        },{
          label: 'ci.common.brand.undelivered',
          value: CustomerStatus.UNDELIVERED
        },{
          label: 'ci.common.brand.delivery_not_confirmed',
          value: CustomerStatus.DELIVERY_NOT_CONFIRMED
        },{
          label: 'ci.common.brand.rejected',
          value: CustomerStatus.REJECTED
        }

      ],
      [Channels.WHATSAPP]: [
        {
          label: 'ci.common.brand.sent',
          value: WhatsappStatus.SENT
        },
        {
          label: 'ci.common.brand.delivered',
          value:  WhatsappStatus.DELIVERED
        }, {
          label: 'ci.common.brand.readStatus',
          value:  WhatsappStatus.READ
        }, {
          label: 'ci.common.brand.rejected',
          value: WhatsappStatus.REJECTED
        }, {
          label: 'ci.common.brand.failed',
          value: WhatsappStatus.FAILED
        }, {
          label: 'ci.common.brand.delivery_not_confirmed',
          value: WhatsappStatus.DELIVERY_NOT_CONFIRMED
        }
      ],
      [Channels.VOICE]: [{
          label: 'ci.common.brand.voiceStatus.queued',
          value: VoiceStatus.QUEUED
        },{
          label: 'ci.common.brand.voiceStatus.cancelled',
          value: VoiceStatus.CANCELLED
        },{
          label: 'ci.common.brand.voiceStatus.completed',
          value: VoiceStatus.COMPLETED
        },{
          label: 'ci.common.brand.voiceStatus.busy',
          value: VoiceStatus.BUSY
        },{
          label: 'ci.common.brand.voiceStatus.failed',
          value: VoiceStatus.FAILED
        },{
          label: 'ci.common.brand.voiceStatus.no_answer',
          value: VoiceStatus.NO_ANSWER
        },{
          label: 'ci.common.brand.voiceStatus.unknown',
          value: VoiceStatus.UNKNOWN
        },{
          label: 'ci.common.brand.voiceStatus.rejected',
          value: VoiceStatus.REJECTED
        }],
    }
}


@Injectable()
export class StatusMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    smsStatuses$ = this.select(
      this.entities$,
      (entities) => entities[Channels.SMS].map(e => {
        return {
          ...e,
          label: this.translateService.instant(e.label)
        }
      })
    )

    whatsappStatuses$ = this.select(
      this.entities$,
      (entities) => entities[Channels.WHATSAPP].map(e => {
        return {
          ...e,
          label: this.translateService.instant(e.label)
        }
      })
    )


    voiceStatuses$ = this.select(
      this.entities$,
      (entities) => entities[Channels.VOICE].map(e => {
        return {
          ...e,
          label: this.translateService.instant(e.label)
        }
      })
    )


    viewEntities$ = this.select(
      this.smsStatuses$,
      this.whatsappStatuses$,
      this.voiceStatuses$,
      (sms, whatsapp, voice) => {
        return {
          [Channels.SMS]: sms,
          [Channels.WHATSAPP]: whatsapp,
          [Channels.VOICE]: voice
        }
      }
    )



    //updaters
    // setEntities = this.updater((state, entities: any[]) => {
    //   return {...state, entities}
    // })
}
