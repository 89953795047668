<ng-container *ngIf="vm$ | async as vm">
  <uni-section>
    <uni-title-container>
        <uni-title-item>
          {{'ci.common.unishield' | translate}}
        </uni-title-item>
    </uni-title-container>
  </uni-section>

  <div class="tabs">
    <app-tab>
      <app-tab-header-selector
          (onTabSelect)="selectTab($event)"
          [buttons]="vm.tabs"
          [active]="vm.selectedTabValue"
      ></app-tab-header-selector>
    </app-tab>

  </div>
  <div class="filter-controls">
    <app-filter-panel-account-id></app-filter-panel-account-id>
    <app-filter-panel-date></app-filter-panel-date>
  </div>
  <app-overview></app-overview>
  <app-geopermissions></app-geopermissions>
  <app-blocked-words></app-blocked-words>
</ng-container>
